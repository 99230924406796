import { lazy } from 'react'

// project import
import Loadable from 'components/Loadable'
import { MODULE } from 'constants/list-module'

const LuckyGame = Loadable(lazy(() => import('pages/LuckyGame/Listing')))

const CreateSpin = Loadable(lazy(() => import('pages/LuckyGame/Create')))
const EditGame = Loadable(lazy(() => import('pages/LuckyGame/Edit')))
const ListGamer = Loadable(lazy(() => import('pages/LuckyGame/Listing/ListGamer')))

const LuckyGameRoutes = [
  {
    path: 'lucky-game',
    element: <LuckyGame />,
    code: MODULE.LUCKY_GAME,
  },
  {
    path: 'lucky-game/create',
    element: <CreateSpin />,
    code: MODULE.LUCKY_SPIN,
  },
  {
    path: 'lucky-game/:id',
    element: <EditGame />,
    code: MODULE.LUCKY_GAME,
  },
  {
    path: 'lucky-game/:id/list-gamer',
    element: <ListGamer />,
    code: MODULE.LUCKY_GAME,
  },
]

export default LuckyGameRoutes